import { SecondaryAlt } from './SecondaryAlt';
import { Secondary } from './Secondary';
import { Primary }from './Primary';
import { IButtonProps } from './types';

export const Buttons: Record<string, React.FC<IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>>> = {
    Primary,
    Secondary,
    SecondaryAlt,
}
