import * as React from 'react';
import { getMenuItems, MenuType, IFooterMenuList } from '../helpers/queries/headerFooter';

export interface IFooterContext {
    lists: IFooterMenuList[];
}

const defaultContext: IFooterContext = {
    lists: new Array<IFooterMenuList>(),
};

export const FooterContext = React.createContext<IFooterContext>(defaultContext);

export const FooterProvider: React.FC<{}> = (props) => {
    const contextValue = {
        lists: getMenuItems(MenuType.Footer),
    };

    return (
        <FooterContext.Provider value={contextValue}>
            {props.children}
        </FooterContext.Provider>
    )
}
