import * as React from 'react';
import styled from 'styled-components';

interface IContentContainerProps {
    className?: string;
}

const Container = styled.div`
    display: block;
    width: 100%;
    height: max-content;
    padding-right: 1rem !important;
    padding-left: 1rem !important;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        padding-right: 2.5vw !important;
        padding-left: 2.5vw !important;
    }

    @media (min-width: ${props => props.theme.screensizes.large}) {
        padding-right: 7.5vw !important;
        padding-left: 7.5vw !important;
    }

    @media (min-width: ${props => props.theme.screensizes.xxlarge}) {
        padding-right: 20vw !important;
        padding-left: 20vw !important;
    }
`;

export const ContentContainer: React.FC<IContentContainerProps> = (props) => {
    return (
        <Container className={props.className ? `${props.className} content-container` : 'content-container'}>
            {props.children}
        </Container>
    );
};