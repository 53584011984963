import * as React from 'react';
import styled from 'styled-components';
import GoCircle from '../../../images/icons/go-circle.svg';

const StyledSubscribeForm = styled.form.attrs({
    action: 'https://gasmonitor-point.us6.list-manage.com/subscribe/post?u=4264f8e51a&amp;id=fcaf8a9ccb',
    name: 'mc-embedded-subscribe-form',
    target: '_blank',
    method: 'post'
 })`
    width: 100%;
    position: relative;
`;

const StyledSubscribeFormContent = styled.div`
    position: relative;
`

const StyledEmailInput = styled.input.attrs({ type: 'email', name: 'EMAIL', required: true, })`
    width: 100%;
    padding: 10px;
    height: 44px;
    font-family: ${props => props.theme.fonts.din};
    font-size: 16px;
    color: ${props => props.theme.colors.greatlyGrey};
    background: var(--mobyDickWhite);
    &:focus {
        outline: none;
    }

    ::placeholder {
        color: ${props => props.theme.colors.greatlyGrey};
    }
`;

const StyledSubmitButton = styled.button.attrs({ value: 'Subscribe', name: 'subscribe', type: 'submit' })`
    width: 44px;
    height: 44px;
    position: absolute;
    top: 0;
    right: 0;
`;

export const SubscribeForm: React.FC<{}> = () => {
    return (
        <StyledSubscribeForm
            noValidate
        >
            <StyledSubscribeFormContent>
                <StyledEmailInput placeholder="Email" />
                <StyledSubmitButton title="Signup">
                    <GoCircle />
                </StyledSubmitButton>
            </StyledSubscribeFormContent>
        </StyledSubscribeForm>
    )
}
