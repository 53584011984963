import * as React from 'react';
import styled from 'styled-components';
import { IFooterMenuItem } from '@/src/helpers/queries/headerFooter';
import FacebookCircle from '../../images/icons/facebook-circle.svg';
import LinkedinCircle from '../../images/icons/linkedin-circle.svg';
import TwitterCircle from '../../images/icons/twitter-circle.svg';

export const SOCIAL_ICONS: Record<string, React.FC> = {
    facebook: FacebookCircle,
    twitter: TwitterCircle,
    linkedin: LinkedinCircle,
};

export const StyledSocialLinks = styled.div`
    font-family: ${props => props.theme.fonts.din};
    color: ${props => props.theme.colors.nearlyNavy};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 50px 0 40px;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        justify-content: flex-start;
        margin: 35px 0 0;
    }
`

export const StyledSocialLinksTitle = styled.h4`
    font-weight: bold;
    font-size: 20px;
    font-family: ${props => props.theme.fonts.dinNarrow};
    margin-bottom: 25px;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        text-align: left;
    }
`

export const StyledSocialLinksList = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        justify-content: flex-start;
    }
`

export const StyledSocialLinksListItem = styled.li`
    margin-right: 16px;

    &:last-child {
        margin-right: 0;
    }

    a {
        text-decoration: none;
    }
`;

interface Props {
    header?: string
    links: IFooterMenuItem[]
}

export const SocialLinks: React.FC<Props> = (props) => {
    return (
        <StyledSocialLinks>
            {props.header && (
                <StyledSocialLinksTitle>{props.header}</StyledSocialLinksTitle>
            )}

            <StyledSocialLinksList>
                {props.links.map((link, index) => {
                    if (!(link.title.toLowerCase() in SOCIAL_ICONS)) {
                        return null;
                    }

                    const Icon = SOCIAL_ICONS[link.title.toLowerCase()];
                    if (!Icon) return null;

                    return (
                        <StyledSocialLinksListItem key={index}>
                            <a href={link.url} target="_blank" title={link.title}>
                                <Icon />
                            </a>
                        </StyledSocialLinksListItem>
                    )
                })}
            </StyledSocialLinksList>
        </StyledSocialLinks>
    )
}
