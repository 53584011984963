import { IFooterMenuItem } from '@/src/helpers/queries/headerFooter';
import { getCorrectSolutionUrl } from '@/src/helpers/urls';
import * as React from 'react';
import styled from 'styled-components';

const StyledSiteLinks = styled.div`
    font-family: ${props => props.theme.fonts.din};
    color: ${props => props.theme.colors.nearlyNavy};
`

const StyledSiteLinksTitle = styled.h4`
    font-weight: bold;
    font-family: ${props => props.theme.fonts.dinNarrow};
    font-size: 20px;
    margin-bottom: 25px;
    text-transform: uppercase;
`

const StyledSiteLinksList = styled.ul`
    list-style: none;
`

const StyledSiteLinksListItem = styled.li`
    font-size: 16px;
    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0;
    }

    a {
        color: ${props => props.theme.colors.nearlyNavy};
        text-decoration: none;
    }
`

interface ISiteLinksProps {
    header?: string;
    links: IFooterMenuItem[];
}

export const SiteLinks: React.FC<ISiteLinksProps> = (props) => {
    return (
        <StyledSiteLinks>
            {props.header && (
                <StyledSiteLinksTitle>{props.header}</StyledSiteLinksTitle>
            )}

            <StyledSiteLinksList>
                {props.links.map((link, index) => {
                    const PAGES_RELATING_TO_SOLUTIONS = ['/solutions/products/', '/solutions/services/']

                    const uri = link.url
                    let url = PAGES_RELATING_TO_SOLUTIONS.includes(uri) ? `/solutions#${getCorrectSolutionUrl(uri)}` : uri
                    return (
                        <StyledSiteLinksListItem key={index}>
                            <a 
                                href={ url || '' }
                            >
                                {link.title}
                            </a>
                        </StyledSiteLinksListItem>
                    )
                })}
            </StyledSiteLinksList>
        </StyledSiteLinks>
    )
}
