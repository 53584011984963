import * as React from 'react';
import styled from 'styled-components';
import { IHeaderMenuItem } from '@/src/helpers/queries/headerFooter';
import { getWindow } from '@/src/helpers/ssrSafeGlobals';

interface MenuItemProps {
    data: Omit<IHeaderMenuItem, 'childItems'>;
    isOpen?: boolean;
    resetMenu?: any;
}

const Link = styled.a<{isActive: boolean}>`
    display: block;
    width: 100%;
    height: max-content;
    margin-top: 1rem;
    padding-bottom: 1rem;
    color: ${props => props.theme.colors.nearlyNavy};
    font-family: ${props => props.theme.fonts.din};
    font-size: 20px;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: ${props => props.theme.colors.casperGrey};
    @media(max-width: ${props => props.theme.screensizes.mediumValue - 1}px) {
        &:last-child {
            border: none;
        }
    }

    @media(min-width: ${props => props.theme.screensizes.medium}) {
        flex: 0 0 auto;
        display: inline;
        width: max-content;
        margin: 0 1rem;
        font-size: 16px;
        border-width: 0 0 4px 0;
        border-color: ${props => props.isActive ? props.theme.colors.meaningfulMarigold : 'transparent'};
    }
`;

export const MenuItem: React.FC<MenuItemProps> = ({ data, isOpen, resetMenu }) => {
    const isActive = getWindow().location.href.includes(data.url) || false;

    return (
        <Link 
            {...typeof resetMenu !== 'undefined' && { onClick: () => (resetMenu(!isOpen)) } }
            href={data.url} isActive={isActive}
        >
            {data.title}
        </Link>
    );
};