import * as React from 'react';
import styled from 'styled-components'
import { IButtonProps } from '../types';

const ButtonWithLink = styled.a`
    margin: 0 0 auto;
    padding: 16px 24px;
    border: 1px solid white;
    background-color: transparent;
    color: white;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    display: block;
    max-width: 225px;

    &:hover,
    &:active,
    &:focus {
        background-color: white;
        border-color: ${props => props.theme.colors.nearlyNavy};
        color: ${props => props.theme.colors.nearlyNavy};
    }
`

const ButtonWithoutLink = styled.button`
    margin: 0 0 auto;
    padding: 16px 24px;
    border: 1px solid white;
    background-color: transparent;
    color: white;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    display: block;
    max-width: 225px;

    &:hover,
    &:active,
    &:focus {
        background-color: white;
        border-color: ${props => props.theme.colors.nearlyNavy};
        color: ${props => props.theme.colors.nearlyNavy};
    }
`

export const SecondaryAlt: React.FC<IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
    return (
        props?.href ? (
            <ButtonWithLink
                { ...props?.href && { href: props.href }}
                { ...props?.type && { type: props.type }}
                { ...props?.disabled && { disabled: props.disabled }}
                target={ props?.target || '_blank' }
            >
                { props.text }
            </ButtonWithLink>
        ) : (
            <ButtonWithoutLink
                { ...props?.type && { type: props.type }}
                { ...props?.disabled && { disabled: props.disabled }}
            >
                { props.text }
            </ButtonWithoutLink>
        )
    )
}