import * as React from 'react';
import { WindowLocation } from "@reach/router";
import { getThemeSettings, IThemeSettings } from '../helpers/queries/themeSettings';

export interface IGlobalContext {
    themeSettings: IThemeSettings;
    location: Partial<WindowLocation<unknown>>
}

const defaultContext: IGlobalContext = {
    themeSettings: {} as IThemeSettings,
    location: {
        pathname: '',
        search: '',
        state: {},
        hash: '',
    }
}

export const GlobalContext = React.createContext<IGlobalContext>(defaultContext);

export const GlobalProvider: React.FC<{location:Partial<WindowLocation<unknown>>}> = (props) => {
    const contextValue : IGlobalContext = {
        themeSettings: getThemeSettings(),
        location: props.location,
    }

    return (
        <GlobalContext.Provider value={contextValue}>
            {props.children}
        </GlobalContext.Provider>
    )
}
