// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-tsx": () => import("./../../../src/pages/industries.tsx" /* webpackChunkName: "component---src-pages-industries-tsx" */),
  "component---src-pages-knowledge-faqs-tsx": () => import("./../../../src/pages/knowledge/faqs.tsx" /* webpackChunkName: "component---src-pages-knowledge-faqs-tsx" */),
  "component---src-pages-knowledge-news-tsx": () => import("./../../../src/pages/knowledge/news.tsx" /* webpackChunkName: "component---src-pages-knowledge-news-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */),
  "component---src-pages-why-us-tsx": () => import("./../../../src/pages/why-us.tsx" /* webpackChunkName: "component---src-pages-why-us-tsx" */),
  "component---src-templates-brochure-page-tsx": () => import("./../../../src/Templates/BrochurePage.tsx" /* webpackChunkName: "component---src-templates-brochure-page-tsx" */),
  "component---src-templates-case-study-page-tsx": () => import("./../../../src/Templates/CaseStudyPage.tsx" /* webpackChunkName: "component---src-templates-case-study-page-tsx" */),
  "component---src-templates-contact-us-page-tsx": () => import("./../../../src/Templates/ContactUsPage.tsx" /* webpackChunkName: "component---src-templates-contact-us-page-tsx" */),
  "component---src-templates-knowledge-page-tsx": () => import("./../../../src/Templates/KnowledgePage.tsx" /* webpackChunkName: "component---src-templates-knowledge-page-tsx" */),
  "component---src-templates-news-page-tsx": () => import("./../../../src/Templates/NewsPage.tsx" /* webpackChunkName: "component---src-templates-news-page-tsx" */),
  "component---src-templates-placeholder-page-tsx": () => import("./../../../src/Templates/PlaceholderPage.tsx" /* webpackChunkName: "component---src-templates-placeholder-page-tsx" */),
  "component---src-templates-products-page-tsx": () => import("./../../../src/Templates/ProductsPage.tsx" /* webpackChunkName: "component---src-templates-products-page-tsx" */),
  "component---src-templates-service-page-tsx": () => import("./../../../src/Templates/ServicePage.tsx" /* webpackChunkName: "component---src-templates-service-page-tsx" */)
}

