import * as React from 'react';
import styled from 'styled-components';
import { getDocument, getWindow } from '@/src/helpers/ssrSafeGlobals';
import { Menu, Close } from '@styled-icons/material-twotone';
import { SearchBar } from '../SearchBar';
import { MobileDropdown } from './MobileDropdown';

const MENU_TITLE = "Mobile Menu";

const IconsWrapper = styled.div`
    grid-area: icons;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;

    > button {
        width: 6vw;
        height: 6vw;
        margin: 0 1rem 0 0;
        padding: 0;

        > svg {
            width: 100%;
            height: 100%;
            fill: ${props => props.theme.colors.nearlyNavy};
        }
    }
`;

export const MobileMenu: React.FC<{}> = (props) => {
    const dropdownContainerRef = React.createRef<HTMLDivElement>();
    const [dropdownTopMargin, setDropdownTopMargin] = React.useState(0);
    const [openMenu, setOpenMenu] = React.useState('');
    const handleMenuIconClick = () => {
        const isCurrentlyOpen = openMenu === MENU_TITLE;
        setOpenMenu(isCurrentlyOpen ? '' : MENU_TITLE);
        setDropdownTopMargin(dropdownContainerRef.current?.getBoundingClientRect().bottom || 0);
        if (isCurrentlyOpen) {
            getDocument().body.classList.remove('modal-open');
        } else {
            getDocument().body.classList.add('modal-open');
            getWindow().scrollTo(0, 0);
        }
    }

    if (!openMenu) getDocument().body.classList.remove('modal-open');

    return (
        <IconsWrapper ref={dropdownContainerRef}>
            <SearchBar
                containerRef={dropdownContainerRef}
                openMenu={openMenu}
                setOpenMenu={setOpenMenu}
            />
            <button onClick={handleMenuIconClick}>
                {openMenu === MENU_TITLE ? <Close /> : <Menu />}
            </button>
            {openMenu === MENU_TITLE &&
                <MobileDropdown topMargin={dropdownTopMargin} resetOpenMenu={ setOpenMenu }/>
            }
        </IconsWrapper>
    )
};