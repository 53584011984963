import * as React from 'react';
import styled from 'styled-components';
import { GlobalContext } from '@/src/contexts/GlobalContext';

const StyledCopyright = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 16px;
    border-top: 1px solid ${props => props.theme.colors.casperGrey};

    @media (min-width: ${props => props.theme.screensizes.large}) {
        padding: 16px 0;
    }
`

const StyledCopyrightText = styled.p`
    font-size: 16px;
    font-family: ${props => props.theme.fonts.din};
    color: ${props => props.theme.colors.nearlyNavy};
    margin: 0;
`

export const Copyright: React.FC<{}> = () => {
    const { copyright } = React.useContext(GlobalContext).themeSettings.siteDetails
    if (!copyright) return null;

    const currentYear = (new Date).getFullYear();
    return (
        <StyledCopyright>
            <StyledCopyrightText>&copy; {currentYear} { copyright }</StyledCopyrightText>
        </StyledCopyright>
    )
}
