import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle `
    *,*:before,*:after {
        margin: 0;
        padding: 0;
        border: none;
        line-height: 1;
        box-sizing: border-box;
    }

    html {
        --white : #ffffff;
        --almostWhite:#F4F4F5;
        --casperGrey: #D2D3D9;
        --greatlyGrey: #4A4F66;
        --meaningfulMarigold: #FFD138;
        --mobyDickWhite: #FFF;
        --nearlyNavy: #1D2340;
        --opaqueOldJeans: rgba(29,35,64, 0.85);
        --din-font: din-2014, sans-serif;
        --dinNarrow-font: din-2014-narrow, sans-serif;
        --xxsmall: 320px;
        --xsmall: 475px;
        --small: 550px;
        --smallValue: 550px;
        --medium: 800px;
        --large: 1200px;
        --largeValue: 1200;
        --xlarge: 1450px;
        --xlargeValue: 1450;
        --xxlarge: 1600px;
        scroll-behavior:smooth;
    }

    .din-narrow {
        font-family : var(--dinNarrow-font);
    }

    .centered {
        text-align:center;
    }
    .align-left {
        text-align: left !important;
    }

    .col-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        @media (min-width: ${props => props.theme.screensizes.medium}) {
            flex-direction: row;
            justify-content:space-between;
        }
        .col-25 {
            @media (min-width: ${props => props.theme.screensizes.medium}) {
                flex: 0 0 calc(25% - 15px);
            }
        }
        .col-30 {
            @media (min-width: ${props => props.theme.screensizes.medium}) {
                flex: 0 0 calc(30% - 15px);
            }
        }
        .col-40 {
            @media (min-width: ${props => props.theme.screensizes.medium}) {
                flex: 0 0 calc(40% - 15px);
            }
        }
    
        .col-50 {
            flex: 1;
            margin: 10px auto;
            @media (min-width: ${props => props.theme.screensizes.medium}) {
                flex: 0 0 calc(50% - 15px);
                margin: 20px 0;
            }
        }
        .col-60 {
            @media (min-width: ${props => props.theme.screensizes.medium}) {
                flex: 0 0 calc(60% - 15px);
            }
        }
        .col-70 {
            @media (min-width: ${props => props.theme.screensizes.medium}) {
                flex: 0 0 calc(70% - 15px);
            }
        }
        .col-75 {
            @media (min-width: ${props => props.theme.screensizes.medium}) {
                flex: 0 0 calc(75% - 15px);
            }
        }

    }
    .with-sidebar {
        display: flex;
        flex-direction: column;
        margin: 50px 0px;
        @media (min-width: ${props => props.theme.screensizes.medium}) {
            flex-direction: row;
        }
        main {
            @media (min-width: ${props => props.theme.screensizes.medium}) {
                flex: 1 0 calc(75% - 30px);
            }
        }

        aside {
            margin-bottom: 50px;
            @media (min-width: ${props => props.theme.screensizes.medium}) {
                flex: 0 0 calc(25% - 30px);
                margin-bottom: 0px;
            }
        }

        
    }
    /* TYPOGRAPHY */
    h1,h2,h3,h4,h5 {
        font-family: var(--din-font);
    }


    h2 {
        color: var(--nearlyNavy);
        font-size: 40px;
        font-weight:400;
        font-family: var(--din-font);
    }

    h3 {
       
    }
    
    h4 {
        margin-bottom: 1rem;
        font-size: 32px;
        font-weight: 400;
        font-family: var(--din-font);;
        
    }

    p {
        font-size: 16px;
        line-height:20px;
        font-family: var(--din-font);
        color: var(--nearlyNavy);
        margin-bottom:16px;
        @media (min-width: ${props => props.theme.screensizes.medium}) {
            margin-bottom:24px;
        }
        &.content-intro {
            font-size:20px;
            line-height:25px;
        }
    }

    small {
        font-size: 1.px;
        display: block;
        margin: 1.5rem 0;
    }

    strong {
        font-weight:600;
    }

    input {
        background: var(--white);
    }



    ul,ol {
        list-style:none;
        padding-left:0px;
        li {
            font-family: var(--din-font);
        }
    }


    img {
        width:100%;
        height:auto;
        line-height:0px;
    }


    a {
        font-family: var(--din-font);
        cursor: pointer;
    }

    a,.link {
        text-decoration:none;
        &:visited {
            color:inherit;
        }
    }

`;



export default GlobalStyles;