import * as React from 'react';
import styled from 'styled-components';
import { SiteLogo } from '../../Common/SiteLogo';
import { StoreDetails } from '../StoreDetails';
import { SiteLinks } from '../SiteLinks';
import { SocialLinks } from '../SocialLinks';
import { FooterContext } from '@/src/contexts/FooterContext';

const FooterInfoWrapper = styled.div`
    position: relative;
    width: 100vw;
    height: max-content;
    padding: 32px;
    background-color: ${props => props.theme.colors.mobyDickWhite};
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: stretch;
`;

const FooterInfoSection = styled.section`
    flex: 0 1 auto;
    width: max-content;
    max-width: 30%;

    @media(min-width: ${props => props.theme.screensizes.large}) {
        max-width: 20%;
    }
`;

const StyledLogo = styled(SiteLogo)`
    flex: 0 0 auto;
    display: block;
    width: 20%;
    margin: 0 40% 50px;

    @media (min-width: ${props => props.theme.screensizes.large}) {
        display: inline-block;
        width: 12.5%;
        margin: 0;
    }
`;

export const DesktopFooterInfo: React.FC<{}> = () => {
    const footerContext = React.useContext(FooterContext);
    const mainLists = footerContext.lists.filter((_list, index) => index <= footerContext.lists.length - 3);
    const finalTwoLists = footerContext.lists.filter((_list, index) => index >= mainLists.length);

    return (
        <FooterInfoWrapper>
            <StyledLogo />
            <FooterInfoSection>
                <StoreDetails header="Contact Us" />
            </FooterInfoSection>
            {mainLists.map((list, index) => (
                <FooterInfoSection key={index}>
                    <SiteLinks header={list.title} links={list.children}  />
                </FooterInfoSection>
            ))}
            <FooterInfoSection>
                <SiteLinks header={finalTwoLists[0].title} links={finalTwoLists[0].children} />
                <SocialLinks header={finalTwoLists[1].title} links={finalTwoLists[1].children} />
            </FooterInfoSection>
        </FooterInfoWrapper>
    )
}
