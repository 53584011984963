import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { FooterProvider } from '../../contexts/FooterContext';
import { debounce } from '@/src/helpers/debounce';
import { getWindow } from '@/src/helpers/ssrSafeGlobals';
import { DesktopFooter } from './DesktopFooter';
import { MobileFooter } from './MobileFooter';

const StyledFooter = styled.footer`
    width: 100vw;
    height: max-content;
    min-height: 50px;
    background-color: ${props => props.theme.colors.mobyDickWhite};
    display: flex;
    flex-direction: column;
    z-index: 999;
`;

export const Footer: React.FC<{}> = () => {
    const theme = useTheme();
    const [showMobileFooter, setShowMobileFooter] = React.useState(false);

    const _handleResize = () => {
        setShowMobileFooter(window.innerWidth < theme.screensizes.mediumValue);
    }

    const handleResize = debounce(_handleResize, 250);

    React.useEffect(() => {
        const w = getWindow()

        handleResize();
        w.addEventListener('resize', handleResize);
        return () => { w.removeEventListener('resize', handleResize); }
    }, []);

    return (
        <StyledFooter>
            <FooterProvider>
                {showMobileFooter ? <MobileFooter /> : <DesktopFooter />}
            </FooterProvider>
        </StyledFooter>
    )
}
