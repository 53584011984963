import { ChevronDown } from './ChevronDown';
import { ChevronUp } from './ChevronUp';
import { ChevronLeft } from './ChevronLeft';
import { ChevronRight } from './ChevronRight';
import { ChevronCircle } from './ChevronCircle';
import { Play } from './Play';

export default {
    ChevronDown,
    ChevronUp,
    ChevronLeft,
    ChevronRight,
    ChevronCircle,
    Play
};