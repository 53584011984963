import * as React from 'react';
import styled from 'styled-components';
import { Search, Close } from '@styled-icons/material-twotone';
import { SearchInput } from '../Common/Search/SearchInput';
import { navigate } from 'gatsby';

interface SearchBarProps {
    containerRef: React.RefObject<HTMLElement>;
    openMenu: string;
    setOpenMenu: React.Dispatch<React.SetStateAction<string>>;
}

const TITLE = "Search";

const Button = styled.button`
    width: max-content;
    height: max-content;
    margin-left: .5rem;
    padding: 0 0 1rem;
    background-color: ${props => props.theme.colors.mobyDickWhite};
    color: ${props => props.theme.colors.nearlyNavy};
    font-family: ${props => props.theme.fonts.din};
    font-size: 16px;
`;

const SearchIcon = styled(Search)`
    width: 24px;
    fill: ${props => props.theme.colors.nearlyNavy};
`;
const CloseIcon = styled(Close)`
    width: 24px;
    fill: ${props => props.theme.colors.nearlyNavy};
`;

export const SearchBar: React.FC<SearchBarProps> = (props) => {
    const isOpen = props.openMenu === TITLE;
    const [value, setInputValue] = React.useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value)
    const handleDropdown = () => {
        props.setOpenMenu(isOpen ? '' : TITLE)
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        navigate(`/search?${value}`, { state: { searchQuery: value }});
    }

    return (
        <>
            <Button onClick={() => handleDropdown()}>
                {isOpen ? <CloseIcon /> : <SearchIcon />}
            </Button>
            {isOpen  &&
                <SearchInput onChange={ handleChange } onSubmit={ handleSubmit } />
            }
        </>
    );
};