import * as React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import Icons from '../Icons';
import ChevronCircle from '../../images/icons/chevron-circle.svg'

const StyledCollapsible = styled.div`
    width: 100%;

    &.collapsible--alt {
        background: var(--almostWhite);
        margin-bottom: 10px;
        .title-container-alt {
            background: var(--casperGrey);
            padding: 15px;
          
            span {
                right: 20px;
            }
        }
        .content-inner {
            padding: 0px 16px;
        }
        .title {
            margin-bottom: 0px;
            text-transform: capitalize;
        }
    }
`;

const StyledCollapsibleTitle = styled.div`
    font-size: 20px;
    font-family: ${props => props.theme.fonts.dinNarrow};
    color: ${props => props.theme.colors.nearlyNavy};
    text-transform: uppercase;
    font-weight: bold;
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.colors.casperGrey};
    outline: none;
    display:flex;
    cursor:pointer;
    .title {
        font-family: ${props => props.theme.fonts.din};
    }
`;

const StyledCollapsibleContent = styled.div`
    width: 100%;
    padding: 20px 0;
    display: none;

    .collapsible--open & {
        display: flex;
        flex-direction: column;
    }
`;

const ChevronCircleUp = styled(ChevronCircle)`
    transform:rotate(180deg);
`;

const ChevronDown = styled(Icons.ChevronDown)`
    border-color: ${props => props.theme.colors.nearlyNavy};
    position: relative;
    right: 3px;
`

const ChevronUp = styled(Icons.ChevronUp)`
    border-color: ${props => props.theme.colors.nearlyNavy};
    position: relative;
    right: 3px;
`

interface Props {
    title: string
    subTitle?: string
    open?: boolean
    openAlt?: boolean
    setOpen?: any;
    className?: string
    alt?:boolean
    altArrow?:boolean
}

export const Collapsible: React.FC<Props> = (props) => {
    const ref = React.useRef(null)

    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
        setOpen(open || false)
    }, []);

    const isDefault = (typeof props.open !== 'boolean' || props.openAlt)
    return (
        <StyledCollapsible className={cn('collapsible', { 'collapsible--open': props.open || open }, {'collapsible--alt' : props.alt})} ref={ref}>
            <StyledCollapsibleTitle
                {...!isDefault && { onClick: () => {props.setOpen(props.open)} } }
                {...isDefault && { onClick: () => {setOpen(!open)} } }
                className={cn({'title-container-alt' : props.alt})}
            >
                <div className="title-inner">
                    {props.title && (<p className="title">{props.title}</p>)}
                    {props.subTitle && (<p className="sub-title">{props.subTitle}</p>)}
                </div>

                {
                    props.open || open ? (
                    props.altArrow ? <ChevronCircle /> : <ChevronUp width="10px" strokeThickness={3} />
                    ) : (
                        props.altArrow ? <ChevronCircleUp /> : <ChevronDown width="10px" strokeThickness={3} />
                    )
                }
            </StyledCollapsibleTitle>

            <StyledCollapsibleContent>
                {props.children}
            </StyledCollapsibleContent>
        </StyledCollapsible>
    )
}