import * as React from 'react';
import styled from 'styled-components';
import GoCircle from '../../images/icons/go-circle.svg';
import { Link } from 'gatsby';

interface IPagination {
    totalPages:number
    hasNextPage:boolean
    hasPrevPage:boolean
}

const StyledLink = styled.button`
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

`;

const StyledPagination = styled.div`
    text-align:center;
    display: flex;
    align-items: center;
    justify-content:center;
    margin-top:30px;

    .pagination-nav-arrow {
        width: 32px;
        height: 32px;
    }

    .prev {
        transform: rotate(180deg);
    }
    ul {
        display: flex;
        align-items: center;
        padding-left: 0px;
        li {
            margin-right: 16px;
            border-radius: 50%;
            &:first-child {
                margin-left: 20px;
            }
            a {
                font-size: 16px;
                border: 1px solid var(--nearlyNavy);
                padding: 5px 11px;
                cursor:pointer;
                border-radius: 50%;
                color: var(--nearlyNavy);

            transition: all .3s ease-in-out;
                &.active {
                background:var(--meaningfulMarigold);
                border-color: var(--meaningfulMarigold);
                color: var(--nearlyNavy);
                }
                &:hover{
                    background: var(--nearlyNavy);
                    border-color: var(--nearlyNavy);
                    color: var(--mobyDickWhite);
                }
            }
           
        }
    }
    a {
        line-height: 0px;
    }

`;

const Pagination:React.FC<IPagination> = ({ totalPages, hasNextPage, hasPrevPage }) => {
    
    const totalPagesCount = Math.ceil(totalPages / 6)
    const [currentPage, setCurrentPage] = React.useState(1);
    const [prevPage, setHasPrevPage] = React.useState(hasPrevPage);
    const [nextPage, setHasNextPage] = React.useState(hasNextPage);

    const onPrevious = (e) => {
        e.preventDefault()

        if(currentPage === 1) return false;
        setCurrentPage(currentPage - 1)
        currentPage > 1 ? setHasPrevPage(true) : setHasPrevPage(false)
        currentPage < totalPagesCount ? setHasNextPage(true) : setHasNextPage(false)
    }
    const onNext = (e) => {
        e.preventDefault()

        if(currentPage === totalPagesCount) return false;
        setCurrentPage(currentPage + 1)
        currentPage > 1 ? setHasPrevPage(true) : setHasPrevPage(false)
        currentPage < totalPagesCount ? setHasNextPage(true) : setHasNextPage(false)
    }

    return (
        <StyledPagination>
            <StyledLink title="Previous Page" onClick={onPrevious} disabled={!prevPage}>
                <GoCircle className="pagination-nav-arrow prev" />
            </StyledLink>
            <ul>
                { 
                    Array.from({ length: totalPagesCount }).map((_, i) => (
                        <li key={`page-${i}`}>
                            <Link className={(currentPage - 1) === i ? 'active' : ''} to={`page-${i + 1}`}>
                                {i + 1}
                            </Link>
                        </li>
                    ))
                }
            </ul>

            <StyledLink  title="Next Page" onClick={onNext} disabled={!nextPage}>
                <GoCircle className="pagination-nav-arrow next"/>
            </StyledLink>
          
        </StyledPagination>
    )
}

export default Pagination