import * as React from 'react';
interface ObfuscatedLinkProps {
    tel?: string;
    mail?: string;
    linkText?: string;
    className?: string; // Required so that this component can be used with the styled function from styled-components
}

interface ObfuscatedLinkData {
    linkText: string;
    href: string;
}

const styles: React.CSSProperties = {
    display: 'inline-block',
    unicodeBidi: 'bidi-override',
    direction: 'rtl',
    whiteSpace: 'nowrap',
};

function reverseString(s: string): string {
    return s.split('').reverse().join('');
}

function getLinkDataFromProps(props: ObfuscatedLinkProps): ObfuscatedLinkData {
    let linkText = '';
    if (props.linkText) linkText = reverseString(props.linkText);

    let href = '';
    if (props.tel) {
        href = `tel:${props.tel}`;
        linkText = linkText || reverseString(props.tel);
    } else if (props.mail) {
        href = `mailto:${props.mail}`;
        linkText = linkText || reverseString(props.mail);
    }

    return {
        linkText,
        href,
    };
}

/**
 * Renders an anchor component that is not robot-friendly. href attribute is a hash, click event redirects to appropriate email
 * or telephone link. Text within the markup is reversed and then re-reversed with CSS to be user-friendly but prevent being read
 * by robots / site crawlers.
 * 
 * Either the mail OR tel prop should be passed. With both missing, nothing will be rendered.
 * The linkText prop is completely optional. Without it, the string passed as either mail or tel will be used.
 */
export const ObfuscatedLink: React.FC<ObfuscatedLinkProps> = (props) => {
    const { href, linkText } = getLinkDataFromProps(props);

    const handleClick = (event: React.MouseEvent) => {
        event.preventDefault();
        window.location.href = href;
    }

    return (
        <a style={styles} href="#" onClick={handleClick} className={props.className}>{linkText}</a>
    )
}