import styled from 'styled-components';

export const ChevronDown = styled.span<{ width: string, strokeThickness?: number }>`
    display: inline-block;
    background-color: transparent;
    transform: rotate(45deg) translateY(-25%);
    border-style: solid;
    ${props => `
        width: ${props.width};
        height: ${props.width};
        border-width: 0 ${props.strokeThickness || 2}px ${props.strokeThickness || 2}px 0;
        border-color: black;
    `}
`;