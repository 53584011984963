import styled from 'styled-components';

export const ChevronRight = styled.span<{ width: string, strokeThickness?: number }>`
    display: inline-block;
    background-color: transparent;
    transform: rotate(45deg) translateX(-25%);
    border-style: solid;
    ${props => `
        width: ${props.width};
        height: ${props.width};
        border-width: ${props.strokeThickness || 2}px ${props.strokeThickness || 2}px 0 0;
        border-color: black;
    `}
`;