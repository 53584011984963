import * as React from 'react';
import styled from 'styled-components';
import { SubscribeIntro } from '../SubscribeIntro';
import { SubscribeForm } from '../SubscribeForm';

const StyledDesktopNewsletterSubscribe = styled.div`
    margin: 0 auto;
    max-width: 1116px;
    padding: 32px 84px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (min-width: ${props => props.theme.screensizes.large}) {
        width: 80%;
    }
`;

const StyledSubscribeIntroWrapper = styled.div`
    width: 50%;
    padding-right: 32px;

    @media (min-width: ${props => props.theme.screensizes.large}) {
        width: 45%;
        padding-left: 24px;
    }
`

const StyledSubscribeFormWrapper = styled.div`
    width: 50%;
    padding-left: 32px;

    @media (min-width: ${props => props.theme.screensizes.large}) {
        width: 45%;
        padding-left: 24px;
    }
`

export const DesktopNewsletterSubscribe: React.FC<{}> = () => {
    return (
        <StyledDesktopNewsletterSubscribe>
            <StyledSubscribeIntroWrapper>
                <SubscribeIntro />
            </StyledSubscribeIntroWrapper>

            <StyledSubscribeFormWrapper>
                <SubscribeForm />
            </StyledSubscribeFormWrapper>
        </StyledDesktopNewsletterSubscribe>
    )
}
