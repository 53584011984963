import * as React from 'react';
import styled from 'styled-components';
import { getWindow } from '../../../helpers/ssrSafeGlobals';
import { IHeaderMenuItem } from '../../../helpers/queries/headerFooter';
import Icons from '../../Icons';
import { DesktopDropdown } from './DesktopDropdown';

interface MenuItemAccordionProps {
    data: Required<IHeaderMenuItem>;
    containerRef: React.RefObject<HTMLElement>;
    openMenu: string;
    setOpenMenu: React.Dispatch<React.SetStateAction<string>>;
}

const Button = styled.button<{isActive: boolean}>`
    flex: 0 0 auto;
    width: max-content;
    height: max-content;
    margin: 0 1rem;
    padding: 0 0 1rem;
    background-color: ${props => props.theme.colors.mobyDickWhite};
    color: ${props => props.theme.colors.nearlyNavy};
    font-family: ${props => props.theme.fonts.din};
    font-size: 16px;
    border-style: solid;
    border-width: 0 0 4px 0;
    border-color: ${props => props.isActive ? props.theme.colors.meaningfulMarigold : 'transparent'};
`;

const ChevronUp = styled(Icons.ChevronUp)`
    border-color: ${props => props.theme.colors.nearlyNavy};
`;

const ChevronDown = styled(Icons.ChevronDown)`
    border-color: ${props => props.theme.colors.nearlyNavy};
`;

export const DesktopMenuAccordion: React.FC<MenuItemAccordionProps> = (props) => {
    const isOpen = props.openMenu === props.data.title;
    const isActive = isOpen || (getWindow().location.href.includes(props.data.url) || false)
    const [dropdownTopMargin, setDropdownTopMargin] = React.useState(0);
    const handleAccordionClick = () => {
        props.setOpenMenu(isOpen ? '' : props.data.title)
        setDropdownTopMargin(props.containerRef.current?.getBoundingClientRect().bottom || 0);
    }

    return (
        <>
            <Button onClick={() => handleAccordionClick()} isActive={isActive}>
                {props.data.title} {isOpen ? <ChevronUp width={'8px'} /> : <ChevronDown width={'8px'} />}
            </Button>
            {isOpen && <DesktopDropdown topMargin={dropdownTopMargin} data={props.data} />}
        </>
    )
}