import * as React from 'react';
import styled from 'styled-components';
import { GlobalContext } from '@/src/contexts/GlobalContext';

const StyledCertifications = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px 48px;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        padding: 24px 0 48px;
    }
`

const StyledCertificationsList = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: row;
`

const StyledCertificationsListItem = styled.li`
    margin-right: 10px;
    max-width: 85px;
    width: 25%;
    &:last-child {
        margin-right: 0;
    }

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        margin-right: 24px;
    }

    img {
        display: block;
        width: 100%;
    }
`

export const Certifications: React.FC<{}> = () => {
    const { certificationImages } = React.useContext(GlobalContext).themeSettings.siteDetails;
    if (!certificationImages.length) return null;

    return (
        <StyledCertifications className="certifications">
            <StyledCertificationsList>
                {certificationImages.map((image, index) => (
                    <StyledCertificationsListItem key={index}>
                        <img src={ image.sourceUrl } />
                    </StyledCertificationsListItem>
                ))}
            </StyledCertificationsList>
        </StyledCertifications>
    )
}
