import { graphql, useStaticQuery } from 'gatsby';

interface IImageDetails {
    sourceUrl: string;
}

interface ICTADetails {
    title: string;
    link: string;
    image: IImageDetails;
}

interface ICertificationDetails {
    title: string;
    summary: string;
    certifications: Array<IImageDetails>;
}

interface IBannerDetails {
    backgroundImage: IImageDetails;
    headline: string;
    subheading: string;
    cta: ICTADetails;
}

interface ISiteDetails {
    address: string;
    copyright: string;
    certificationImages: Array<IImageDetails>;
    emailAddress: string;
    phoneNumber: string;
    phoneNumber_displayReady: string;
    storeLink: string;
    logo: IImageDetails;
}

export interface IThemeSettings {
    certification: ICertificationDetails;
    suppliers: Array<IImageDetails>;
    banner: IBannerDetails;
    alertBanner: string;
    siteDetails: ISiteDetails;
}

export function getThemeSettings(): IThemeSettings {
    const query = useStaticQuery<GatsbyTypes.ThemeSettingsQuery>(themeSettingsQuery);

    const { global } = (query.wordpress.themeGeneralSettings)

    if (!global ) return {} as IThemeSettings;

    const unformattedPhoneNumber = (global?.siteDetails?.phoneNumber || '').replace(/\s/g, '');

    const phoneNumber = unformattedPhoneNumber.substring(0, 3) === '+44'
        ? unformattedPhoneNumber
        : `+44${unformattedPhoneNumber.substring(1)}`;
    const phoneNumber_displayReady = unformattedPhoneNumber.substring(0, 3) === '+44'
        ? `${unformattedPhoneNumber?.substring(0, 7)} ${unformattedPhoneNumber?.substring(7, 10)} ${unformattedPhoneNumber?.substring(10)}`
        : `${unformattedPhoneNumber?.substring(0, 5)} ${unformattedPhoneNumber?.substring(5, 8)} ${unformattedPhoneNumber?.substring(8)}`;

    return {
        certification: {
            title: global?.certification?.title,
            summary: global?.certification?.summary,
            certifications: global?.certification?.certifications.map((i) => {
                return {
                    sourceUrl: i.image.sourceUrl,
                }
            }) || {} as IImageDetails,
        } || {} as ICertificationDetails,
        suppliers: global?.suppliers.map((i) => {
            return {
                sourceUrl: i.supplier.sourceUrl,
            }
        }) || {} as IImageDetails,
        banner: {
            backgroundImage: {
                sourceUrl: global?.banner?.backgroundImage?.sourceUrl,
            },
            headline: global?.banner?.headline,
            subheading: global?.banner?.subheading,
            cta: {
                title: global?.banner?.cta?.title,
                link: global?.banner?.cta?.link,
                image: {
                    sourceUrl: global?.banner?.cta?.image?.sourceUrl,
                },
            } || {} as ICTADetails,
        } || {} as IBannerDetails,
        alertBanner: global?.alertBanner || '',
        siteDetails: {
            address: global?.siteDetails?.address,
            copyright: global?.siteDetails?.copyright,
            certificationImages: global?.siteDetails?.certifications.map((certification) => {
                return {
                    sourceUrl: certification.sourceUrl,
                }
            }) || {} as IImageDetails,
            emailAddress: global?.siteDetails?.emailAddress,
            phoneNumber: phoneNumber || '',
            phoneNumber_displayReady: phoneNumber_displayReady || '',
            storeLink: global?.siteDetails?.storeLink,
            logo: {
                sourceUrl: global?.siteDetails?.logo?.sourceUrl
            } || {} as IImageDetails,
        } || {} as ISiteDetails,
    }
}

const themeSettingsQuery = graphql`
    query {
        wordpress {
            themeGeneralSettings {
                global {
                    certification {
                        title
                        summary
                        certifications {
                            image {
                                sourceUrl
                            }
                        }
                    }
                    suppliers {
                        supplier {
                            sourceUrl
                        }
                    }
                    banner {
                        subheading
                        headline
                        backgroundImage{
                            sourceUrl
                        }
                        cta {
                            link
                            title
                            image {
                                sourceUrl
                            }
                        }
                    }
                    alertBanner
                    siteDetails {
                        address
                        copyright
                        emailAddress
                        phoneNumber
                        storeLink
                        certifications {
                            sourceUrl
                        }
                        logo {
                            sourceUrl
                        }
                    }
                }
            }
        }
    }
`;