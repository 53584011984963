import { graphql, useStaticQuery } from 'gatsby';
import { getRelativeUrl } from '../urls';
import { decodeHtml } from './decodeHtml';

export enum MenuType {
    Header = 'HEADER',
    Footer = 'FOOTER'
}
export interface IHeaderMenuItem {
    inSeperateBlock: boolean;
    url: string;
    title: string;
    childItems?: IHeaderMenuItem[];
}

export interface IFooterMenuItem {
    url: string;
    title: string;
}

export interface IFooterMenuList {
    title: string;
    children: IFooterMenuItem[];
}

function mapHeaderMenuItems(firstLevelItems: GatsbyTypes.WpGql_MenuItem[], allChildrenItems: GatsbyTypes.WpGql_MenuItem[]): IHeaderMenuItem[] {
    const mappedData = new Array<IHeaderMenuItem>();

    firstLevelItems.forEach(firstLevelItem => {
        const inSeperateBlock = firstLevelItem.options?.inSeperateBlock || false;
        const url = getRelativeUrl(firstLevelItem.url!);
        const title = decodeHtml(firstLevelItem.label!)!;
        const directChildren = allChildrenItems.filter(node => node.parentId === firstLevelItem.id)
        if (!directChildren.length) {
            mappedData.push({
                inSeperateBlock,
                url,
                title,
            });
            return;
        };

        const mappedChildren = mapHeaderMenuItems(directChildren, allChildrenItems);
        mappedData.push({
            inSeperateBlock,
            url,
            title,
            childItems: mappedChildren
        });
    });

    return mappedData;
}

function mapFooterMenuItems(firstLevelItems: GatsbyTypes.WpGql_MenuItem[], allChildrenItems: GatsbyTypes.WpGql_MenuItem[]): IFooterMenuList[] {
    const mappedData = new Array<IFooterMenuList>();

    firstLevelItems.forEach(firstLevelItem => {
        const directChildren = allChildrenItems.filter(node => node.parentId === firstLevelItem.id && !!node.url && !!node.label);
        if (!directChildren.length) return;
        const title = decodeHtml(firstLevelItem.label!)!;

        const mappedChildren = directChildren.map(child => ({
            url: getRelativeUrl(child.url!),
            title: decodeHtml(child.label!)
        }))
        mappedData.push({
            title,
            children: mappedChildren
        });
    });

    return mappedData;
}

export function getMenuItems(menuType: MenuType.Header): IHeaderMenuItem[];
export function getMenuItems(menuType: MenuType.Footer): IFooterMenuList[];
export function getMenuItems(menuType: MenuType) {
        const query = useStaticQuery<GatsbyTypes.HeaderFooterQuery>(queryString);
        const queryItem = menuType === MenuType.Header ? query.wordpress.header : query.wordpress.footer;

        const firstLevelItems = new Array<GatsbyTypes.WpGql_MenuItem[]>();
        const childrenItems = new Array<GatsbyTypes.WpGql_MenuItem[]>();

        // Filter query data. Any missing essential properties mean the menu item is not mapped to the returned data.
        // Items without a parent ID are top-level menu links, all others are children or grandchildren, etc.
        queryItem?.edges?.[0]?.node?.menuItems?.edges?.forEach(edge => {
            if (!edge?.node
                || !edge.node.url
                || !edge.node.label
                || !edge.node.id
                ) return;
            
            // @ts-ignore
            !edge.node.parentId && firstLevelItems.push(edge.node as GatsbyTypes.WpGql_MenuItem[]);

            // @ts-ignore
            edge.node.parentId && childrenItems.push(edge.node as GatsbyTypes.WpGql_MenuItem[])
        });
        // @ts-ignore
        firstLevelItems.sort((nodeA, nodeB) => (nodeA?.order || 0) - (nodeB.order || 0));

        const mapFn = menuType === MenuType.Header ? mapHeaderMenuItems : mapFooterMenuItems;
        // @ts-ignore
        return mapFn(firstLevelItems, childrenItems);
}

const queryString = graphql`
fragment MenuFields on WpGql_MenuItem {
    id
    parentId
    order
    options {
        inSeperateBlock
    }
    url
    label
}

query HeaderFooter {
    wordpress {
        header: menus(where: {location: PRIMARY}) {
            edges {
                node {
                    menuItems(first: 100) {
                        edges {
                            node {
                                ...MenuFields
                            }
                        }
                    }
                }
            }
        }
        footer: menus(where: {location: FOOTER}) {
            edges {
                node {
                    menuItems(first: 100) {
                        edges {
                            node {
                                id
                                parentId
                                label
                                url
                            }
                        }
                    }
                }
            }
        }
    }
}`;