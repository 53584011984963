import * as React from 'react';
import styled from 'styled-components';
import { SubscribeIntro } from '../SubscribeIntro';
import { SubscribeForm } from '../SubscribeForm';

const StyledMobileNewsletterSubscribe = styled.div`
    margin: 0 auto;
    max-width: 1116px;
    padding: 32px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledSubscribeIntroWrapper = styled.div`
    width: 85%;
    margin-bottom: 30px;

    @media (min-width: ${props => props.theme.screensizes.small}) {
        width: 50%;
    }
`;

const StyledSubscribeFormWrapper = styled.div`
    width: 100%;
    margin-bottom: 0;
`;

export const MobileNewsletterSubscribe: React.FC<{}> = () => {
    return (
        <StyledMobileNewsletterSubscribe>
            <StyledSubscribeIntroWrapper>
                <SubscribeIntro />
            </StyledSubscribeIntroWrapper>

            <StyledSubscribeFormWrapper>
                <SubscribeForm />
            </StyledSubscribeFormWrapper>
        </StyledMobileNewsletterSubscribe>
    )
}
