import styled from 'styled-components';
import { ArrowDropDownCircle } from '@styled-icons/material-twotone/ArrowDropDownCircle';

export const ChevronCircle = styled(ArrowDropDownCircle)<{ width: string, color?: string, strokeThickness?: number, rotate?:string }>`
    display: inline-block;
    ${props => `
        width: ${props.width};
        height: ${props.width};
        color: ${props.color || props.theme.colors.mobyDickWhite};
        transform: rotate(${props.rotate || '180deg'});
    `}
`;