import * as React from 'react';
import styled from 'styled-components';
import { GlobalContext } from '@/src/contexts/GlobalContext';

const HomeLink = styled.a`
    display: inline-block;
    width: max-content;
    height: max-content;

    > img {
        width: 100%;
        height: auto;
    }
`;

// className prop is required by styled-components so we can do something like:
// const ParticularUseOfSiteLogo = styled(SiteLogo)``;
// It can be omitted if being used in some other way.
export const SiteLogo: React.FC<{ className?: string}> = ({ className }) => {
    const { logo } = React.useContext(GlobalContext).themeSettings.siteDetails

    if (!logo.sourceUrl) return null;

    return (
        <HomeLink href="/" className={className}>
            <img src={ logo.sourceUrl }  />
        </HomeLink>
    )
}
