import { DefaultTheme } from 'styled-components';

// When making changes here, make sure to add them in styled-components.d.ts as well
export const theme: DefaultTheme = {
    colors: {
        white: '#FFFFFF',
        almostWhite: '#F4F4F5',
        casperGrey: '#D2D3D9',
        greatlyGrey: '#4A4F66',
        meaningfulMarigold: '#FFD138',
        mobyDickWhite: '#FFF',
        nearlyNavy: '#1D2340',
        opaqueOldJeans: 'rgba(29,35,64, 0.85)',
    },
    fonts: {
        din: 'din-2014, sans-serif',
        dinNarrow: "din-2014-narrow, sans-serif",
    },
    screensizes: {
        xxsmall: '320px',
        xxsmallValue: 320,
        xsmall: '475px',
        xsmallValue: 475,
        small: '550px',
        smallValue: 550,
        medium: '800px',
        mediumValue: 800,
        large: '1200px',
        largeValue: 1200,
        xlarge: '1450px',
        xlargeValue: 1450,
        xxlarge: '1600px',
        xxlargeValue: 1600,
    }
} as const;
