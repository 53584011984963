import * as React from 'react';
import styled from 'styled-components';
import { ContactIntro } from '../ContactIntro';
import { ContactActions } from '../ContactActions';

const StyledMobileContactUs = styled.div`
    margin: 0 auto;
    max-width: 1116px;
    padding: 50px 32px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledContactIntroWrapper = styled.div`
    width: 85%;
    margin-bottom: 30px;
`;

const StyledContactActionsWrapper = styled.div`
    width: 100%;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export const MobileContactUs: React.FC<{}> = () => {
    return (
        <StyledMobileContactUs>
            <StyledContactIntroWrapper>
                <ContactIntro />
            </StyledContactIntroWrapper>

            <StyledContactActionsWrapper>
                <ContactActions />
            </StyledContactActionsWrapper>
        </StyledMobileContactUs>
    )
}
