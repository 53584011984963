import * as React from 'react';
import styled from 'styled-components';

const StyledIntroHeader = styled.h3`
    margin: 0 0 15px;
    font-family: ${props => props.theme.fonts.dinNarrow};
    color: ${props => props.theme.colors.meaningfulMarigold};
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        text-align: left;
    }
`

const StyledIntroText = styled.p`
    margin: 0;
    font-family: ${props => props.theme.fonts.din};
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    line-height: 1.2;
    font-weight: 300;
    @media (min-width: ${props => props.theme.screensizes.medium}) {
        text-align: left;
    }
`

export const ContactIntro: React.FC<{}> = () => {
    return (
        <>
            <StyledIntroHeader>Ready To Talk?</StyledIntroHeader>
            <StyledIntroText>Our experienced team are ready to talk about your requirements, service advice and support needs.</StyledIntroText>
        </>
    )
}
