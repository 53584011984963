import * as React from 'react';
import styled, { useTheme } from 'styled-components'
import { debounce } from '@/src/helpers/debounce';
import { getWindow } from '@/src/helpers/ssrSafeGlobals';
import { DesktopNewsletterSubscribe } from './DesktopNewsletterSubscribe';
import { MobileNewsletterSubscribe } from './MobileNewsletterSubscribe';

const StyledNewsletterSubscribe = styled.section`
    width: 100vw;
    height: max-content;
    min-height: 125px;
    background-color: ${props => props.theme.colors.casperGrey};
    display: flex;
`;

export const NewsletterSubscribe: React.FC<{}> = () => {
    const [showMobile, setShowMobile] = React.useState(false);
    const theme = useTheme();

    const _handleResize = () => {
        setShowMobile(window.innerWidth < theme.screensizes.mediumValue);
    }

    const handleResize = debounce(_handleResize, 250);

    React.useEffect(() => {
        const w = getWindow();

        handleResize();
        w.addEventListener('resize', handleResize);
        return () => { w.removeEventListener('resize', handleResize); }
    }, []);

    return (
        <StyledNewsletterSubscribe>
            {showMobile ? <MobileNewsletterSubscribe /> : <DesktopNewsletterSubscribe />}
        </StyledNewsletterSubscribe>
    )
}
