import React from 'react';
import { DefaultLayout } from './src/layouts/DefaultLayout';

import './src/global.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export function wrapPageElement({ element, props }) {
  return <DefaultLayout {...props}>{element}</DefaultLayout>;
}
