import * as React from 'react';
import styled from 'styled-components';

const StyledIntro = styled.p`
    margin: 0;
    font-family: ${props => props.theme.fonts.din};
    color: ${props => props.theme.colors.nearlyNavy};
    text-align: center;
    font-size: 20px;
    line-height: 25px;
    @media (min-width: ${props => props.theme.screensizes.medium}) {
        text-align: left;
        font-size: 24px;
    }
`

export const SubscribeIntro: React.FC<{}> = () => {
    return (
        <StyledIntro>
            Stay up-to-date with our latest news and insights
        </StyledIntro>
    )
}
