import * as React from 'react';
import { getMenuItems, MenuType, IHeaderMenuItem } from '../helpers/queries/headerFooter';

export interface IHeaderContext {
    menuItems: IHeaderMenuItem[];
}

const defaultContext: IHeaderContext = {
    menuItems: new Array<IHeaderMenuItem>(),
};

export const HeaderContext = React.createContext<IHeaderContext>(defaultContext);

export const HeaderProvider: React.FC<{}> = (props) => {
    const contextValue = {
        menuItems: getMenuItems(MenuType.Header),
    };

    return (
        <HeaderContext.Provider value={contextValue}>
            {props.children}
        </HeaderContext.Provider>
    )
}
