import { graphql, Link, useStaticQuery } from 'gatsby';
import moment from 'moment';
import * as React from 'react'
import styled from 'styled-components'
import { ContentContainer } from '../ContentContainer'
import Pagination from '../../Common/Pagination'
import { Buttons } from '../Styles/Interactions';


interface IPostGrid {
    sectionTitle?: string
    postType?: {
        type?:string
        title?:string
    }
    hasPagination?: boolean
    postsCount?:number
    hasViewAll?:boolean
    hideDate?:boolean
    postID?:string
}

const StyledPostGridContainer = styled.div`
    .content-container {
        padding: 5rem 0px;

        h2 {
            font-size: 32px;
            line-height: 31px;
            margin-bottom:30px;
        }

        [class^="Secondary__Button"] {
            margin: 0 auto;
            padding: 20px 15px;
            margin-top: 30px;
        }
    }
`;

const StyledPostGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        justify-content: space-between;
    }
`;

const StyledPost = styled.a`
    flex: 0 0 100%;
    margin-bottom: 20px;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        flex: 0 0 calc(50% - 15px)
    }

    h3 {
        font-weight: 400;
        font-size: 20px;
        margin: 16px 0px;
        color: var(--nearlyNavy);

        @media (min-width: ${props => props.theme.screensizes.medium}) {
            margin: 20px 0px;
        }
    }

    .date {
        color: var(--greatlyGrey);
        font-size: 16px;
    }
`;


const StyledImageWrapper = styled.div<{imgSrc: string}>`
    position: relative;
    background: url('${props => props.imgSrc}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    background-blend-mode:multiply;
    height: 218px;
    @media (min-width: ${props => props.theme.screensizes.medium}) {
        height: 336px;
    }
`;


const PostGrid: React.FC<IPostGrid> = (props) => {
    const [currentPage, setCurrentPage] = React.useState(1);
    const [postType, setPostType] = React.useState(props?.postType?.type);

    const { posts } = useStaticQuery(graphql`
        query {
            posts: wordpress {
                caseStudies(first:100, where: {orderby: {field: DATE, order: ASC}}) {
                    nodes {
                        title
                        date
                        uri
                        id
                        featuredImage {
                            node {
                                sourceUrl
                                date
                                title
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        endCursor
                        startCursor
                    }
                }
                posts(where: {orderby: {field: DATE, order: ASC}}) {
                    nodes {
                        title
                        date
                        uri
                        id
                        featuredImage {
                            node {
                                sourceUrl
                                date
                                title
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        endCursor
                        startCursor
                    }
                }
                brochures(where: {orderby: {field: DATE, order: ASC}}) {
                    nodes {
                        title
                        date
                        uri
                        id
                        featuredImage {
                            node {
                                sourceUrl
                                date
                                title
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        endCursor
                        startCursor
                    }
                }
                whitepapers(where: {orderby: {field: DATE, order: ASC}}) {
                    nodes {
                        title
                        date
                        uri
                        id
                        featuredImage {
                            node {
                                sourceUrl
                                date
                                title
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        endCursor
                        startCursor
                    }
                }
            }
        }
    `);

    let postsArr;
    if(postType === 'caseStudies') {
        postsArr = posts?.caseStudies?.nodes;
    } else if(postType === 'brochures') {
        postsArr = posts?.brochures?.nodes;
    } else if(postType === 'whitepapers') {
        postsArr = posts?.whitepapers?.nodes;
    } else {
        postsArr = posts?.posts?.nodes;
    }
    
    props.postsCount && (postsArr.length = props.postsCount)

    return (
        <StyledPostGridContainer>
            <ContentContainer>
                {props.sectionTitle && (<h2>{props.sectionTitle}</h2>)}
               <StyledPostGrid>
                    {
                       postsArr.map((post, index) => (
                            post.uri !== props?.postID && (
                                <StyledPost key={`post__${post.id}`} href={post.uri}>
                                    <StyledImageWrapper className="img-wrapper" imgSrc={post?.featuredImage?.node.sourceUrl} />
                                    <h3>{post.title}</h3>
                                    { !props.hideDate && (
                                        <p className="date">{moment(post.date).format("Do MMM YYYY")}</p>
                                    )}
                                </StyledPost>
                            )
                        ))
                    }
               </StyledPostGrid>

                { console.log(posts.posts.pageInfo.hasNextPage)}

                { (props.hasPagination && postsArr.length > 2 ) && (
                    <Pagination
                        totalPages={postsArr.length} 
                        hasNextPage={posts.posts.pageInfo.hasNextPage}
                        hasPrevPage={posts.posts.pageInfo.hasPreviousPage}
                    />
                )}
                { (props.hasViewAll && postsArr.length > 2 ) && (
                    <Buttons.Secondary text="Visit News" href="/knowledge/news" target="_self"/>
                )}
            
                
            </ContentContainer>
        </StyledPostGridContainer>
    )
}

export default PostGrid