import { HeaderContext } from '@/src/contexts/HeaderContext';
import * as React from 'react';
import styled from 'styled-components';
import { IHeaderMenuItem } from '../../../helpers/queries/headerFooter';
import { MenuItem } from '../MenuItem';
import { MobileMenuAccordion } from './MobileMenuAccordion';
import { ObfuscatedLink } from '../../Common/ObfuscatedLink';
import { GlobalContext } from '@/src/contexts/GlobalContext';

interface MobileDropdownProps {
    topMargin: number;
    resetOpenMenu: any;
}

const MenuContainer = styled.aside<{ topMargin: number }>`
    position: fixed;
    top: ${props => props.topMargin}px;
    left: 0;
    width: 100vw;
    height: calc(100vh - ${props => props.topMargin}px);
    overflow-y: scroll;
    background-color: ${props => props.theme.colors.almostWhite};
    z-index: -1;
    display: grid;
    grid-template-rows: auto max-content;
    grid-template-columns: 100%;
    grid-template-areas: "links"
                         "footer";
`;

const MenuLinksWrapper = styled.div`
    grid-area: links;
    position: relative;
    padding: 1rem;
    overflow-y: scroll;
    z-index: -1;
`;

const MobileMenuFooter = styled.div`
    grid-area: footer;
    padding: 1rem 5%;
    background-color: ${props => props.theme.colors.mobyDickWhite};
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.5);
`;

const BlockLink = styled.a`
    display: block;
    width: 100%;
    margin: .5rem auto;
    padding: 1rem 2rem;
    background-color: ${props => props.theme.colors.meaningfulMarigold};
    font-size: 18px;
    font-weight: 600;
    font-family: ${props => props.theme.fonts.din};
    color: ${props => props.theme.colors.nearlyNavy};
    text-align: center;
    text-decoration: none;

    > span {
        display: block;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
`;

const SiteInfoWrapper = styled.div`
    width: 100%;
    margin-top: 1rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    > a {
        flex: 0 0 auto;
        font-size: 18px;
        font-family: ${props => props.theme.fonts.din};
        color: ${props => props.theme.colors.nearlyNavy};
    }
`;

export const MobileDropdown: React.FC<MobileDropdownProps> = (props) => {
    const headerContext = React.useContext(HeaderContext);
    const regularLinkItems = new Array<IHeaderMenuItem>();
    const seperateBlockItems = new Array<IHeaderMenuItem>();
    headerContext.menuItems.forEach(menuItem => {
        (!menuItem.inSeperateBlock || menuItem.childItems?.length) && regularLinkItems.push(menuItem);
        menuItem.inSeperateBlock && seperateBlockItems.push(menuItem);
    });
    
    const { siteDetails } = React.useContext(GlobalContext).themeSettings

    return (
        <MenuContainer topMargin={props.topMargin} >
            <MenuLinksWrapper>
                {regularLinkItems.map((menuItem, index) => {
                    const key = `menu-item-${index + 1}`
                    return menuItem.childItems?.length
                        ? <MobileMenuAccordion data={menuItem as Required<IHeaderMenuItem>} key={key} resetOpenMenu={ props.resetOpenMenu }/>
                        : <MenuItem data={menuItem} key={key} />
                })}
            </MenuLinksWrapper>
            <MobileMenuFooter>
                {seperateBlockItems.map((menuItem, index) => {
                    const key = `menu-block-link-${index + 1}`;

                    return (
                        <BlockLink href={menuItem.url} key={key}>
                            {menuItem.title}
                        </BlockLink>
                    );
                })}
                <SiteInfoWrapper>
                    <ObfuscatedLink tel={ siteDetails.phoneNumber } linkText={`Call us on: ${ siteDetails.phoneNumber_displayReady }`} />
                    <a href={ siteDetails.storeLink } target="_blank">Shop Online</a>
                </SiteInfoWrapper>
            </MobileMenuFooter>
        </MenuContainer>
    )
};