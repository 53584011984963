import * as React from 'react';
import styled from 'styled-components';
import { ContactIntro } from '../ContactIntro';
import { ContactActions } from '../ContactActions';

const StyledDesktopContactUs = styled.div`
    margin: 0 auto;
    max-width: 1116px;
    padding: 32px 84px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (min-width: ${props => props.theme.screensizes.large}) {
        width: 70%;
    }

    @media (min-width: ${props => props.theme.screensizes.xlarge}) {
        width: 60%;
    }

    @media (min-width: ${props => props.theme.screensizes.xxlarge}) {
        width: 50%;
    }
`;

const StyledContactIntroWrapper = styled.div`
    width: 60%;
    padding-right: 18px;

    @media (min-width: ${props => props.theme.screensizes.large}) {
        padding-right: 24px;
    }

    @media (min-width: ${props => props.theme.screensizes.xxlarge}) {
        width: 55%;
    }
`

const StyledContactActionsWrapper = styled.div`
    width: 40%;
    padding-left: 18px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;

    @media (min-width: ${props => props.theme.screensizes.large}) {
        padding-left: 24px;
    }

    @media (min-width: ${props => props.theme.screensizes.xxlarge}) {
        width: 45%;
    }
`

export const DesktopContactUs: React.FC<{}> = () => {
    return (
        <StyledDesktopContactUs>
            <StyledContactIntroWrapper>
                <ContactIntro />
            </StyledContactIntroWrapper>

            <StyledContactActionsWrapper>
                <ContactActions />
            </StyledContactActionsWrapper>
        </StyledDesktopContactUs>
    )
}
