import * as React from 'react';
import styled from 'styled-components';
import { MobileFooterInfo } from './MobileFooterInfo';
import { Copyright } from '../Copyright';
import { Certifications } from '../../Common/Certification/Certifications';

const StyledFooter = styled.div`
    position: relative;
    width: 100vw;
    height: max-content;
    background-color: ${props => props.theme.colors.mobyDickWhite};
    padding: 32px 16px 16px;
`;

export const MobileFooter: React.FC<{}> = (props) => {
    return (
        <StyledFooter>
            <MobileFooterInfo />
            <Certifications />
            <Copyright />
        </StyledFooter>
    )
}
