import * as React from 'react';
import styled from 'styled-components';
import { GlobalContext } from '../../contexts/GlobalContext'
import { ObfuscatedLink } from '../Common/ObfuscatedLink';

const StyledStoreDetails = styled.div`
    font-family: ${props => props.theme.fonts.din};
    color: ${props => props.theme.colors.nearlyNavy};
`

const StyledStoreDetailsTitle = styled.h4`
    font-weight: bold;
    font-family: ${props => props.theme.fonts.dinNarrow};
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 25px;
`

const StyledStoreAddressWrapper = styled.address`
    p {
        font-size: 16px;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        strong {
            font-weight: bold;
        }

        a {
            color: ${props => props.theme.colors.nearlyNavy};
            text-decoration: none;
        }
    }
`

const StyledStoreAddress = styled.div`
    p {
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`

interface Props {
    header?: string
}

export const StoreDetails: React.FC<Props> = (props) => {
    const context = React.useContext(GlobalContext);
    const {
        address,
        emailAddress,
        phoneNumber,
        phoneNumber_displayReady,
    } = context.themeSettings.siteDetails

    const addressLines = address.split("\n");

    return (
        <StyledStoreDetails>
            {props.header && <StyledStoreDetailsTitle>Contact Us</StyledStoreDetailsTitle>}

            <StyledStoreAddressWrapper>
                {!!phoneNumber && !!phoneNumber_displayReady &&
                    <p><strong>Call us on:</strong> <ObfuscatedLink tel={phoneNumber} linkText={phoneNumber_displayReady} /></p>
                }
                {!!emailAddress &&
                    <p><strong>Email:</strong> <ObfuscatedLink mail={emailAddress} /></p>
                }
                {!!addressLines.length &&
                    <StyledStoreAddress>
                        {addressLines.map((line, index) => (
                            <p key={`addressLine--${index}`}>{line}</p>
                        ))}
                    </StyledStoreAddress>
                }
            </StyledStoreAddressWrapper>
        </StyledStoreDetails>
    )
}
