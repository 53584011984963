import * as React from 'react';
import styled from 'styled-components';
import { GlobalContext } from '@/src/contexts/GlobalContext';

const P = styled.p`
    width: 100vw;
    height: max-content;
    padding: .5rem 0;
    font-size: 14px;
    text-align: center;
    font-family: ${props => props.theme.fonts.din};
    color: ${props => props.theme.colors.almostWhite};
    background-color: ${props => props.theme.colors.nearlyNavy};
    margin-bottom:0px;
`;

export const AlertBar: React.FC<{}> = () => {
    const { alertBanner } = React.useContext(GlobalContext).themeSettings

    return ( alertBanner ? (
        <P>{ alertBanner }</P>
    ) : (
        null
    ))
};