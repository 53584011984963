import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

import favicon from '../../images/favicon.ico'
import { Loading } from "../Common/Loading"
import { Error } from "../Common/404"
import { gql, useQuery } from "@apollo/client"

const SEARCH_QUERY = gql`
    query Page($id: ID!) {
		themeGeneralSettings {
			global {
				siteDetails {
					logo {
						sourceUrl
					}
				}
			}
		}
        page(idType: URI, id: $id) {
            seo {
                title
				metaDesc
            }
        }
    }
`;


const SEO = ( { location = '' } ) => {
	const loc = ( location === '/' ? '/home/' : location);

    const { loading, error, data  }  = useQuery(SEARCH_QUERY, {
        fetchPolicy: "no-cache",
        variables: { id: loc }
    });

    if ( loading || error ) return null;

    const {
		title,
		metaDesc: description
	} = data.page?.seo

    const {
		logo
	} = data.themeGeneralSettings?.global?.siteDetails

	return (

		<Helmet title={title}>
			<link rel="icon" href={ favicon } />
			<meta name="description" content={description} />
			<meta name="image" content={logo.sourceUrl} />

			<meta property="og:url" content={ `https://pointsafety.com${loc}` } />

			{title && <meta property="og:title" content={title} />}

			{description && (
				<meta property="og:description" content={description} />
			)}

			{logo.sourceUrl && <meta property="og:image" content={logo.sourceUrl} />}
	  	</Helmet>
	)

	// const { pathname } = useLocation()

	// const seo = {
	// 	title: title || wordpress?.generalSettings?.defaultTitle,
	// 	description: description || wordpress?.generalSettings?.defaultDescription,
	//   	url: `https://pointsafety.com${pathname}`,
	// 	image: wordpress?.themeGeneralSettings?.global?.siteDetails?.logo?.sourceUrl || '',
	// }

	// return (
	// 	<Helmet title={seo.title}>
	// 		<link rel="icon" href={ favicon } />
	// 		<meta name="description" content={seo.description} />
	// 		<meta name="image" content={seo?.image} />

	// 		{seo.url && <meta property="og:url" content={seo.url} />}

	// 		{seo.title && <meta property="og:title" content={seo.title} />}

	// 		{seo.description && (
	// 			<meta property="og:description" content={seo.description} />
	// 		)}

	// 		{seo.image && <meta property="og:image" content={seo.image} />}
	//   	</Helmet>
	// )
}

export default SEO