import styled from 'styled-components';
import { PlayCircleOutline } from '@styled-icons/material-twotone';

export const Play = styled(PlayCircleOutline)<{ width: string, color?: string }>`
    display: inline-block;
    ${props => `
        width: ${props.width};
        height: ${props.width};
        color: ${props.color || props.theme.colors.meaningfulMarigold};
    `}
`;