import styled from 'styled-components';

export const ChevronLeft = styled.span<{ width: string, strokeThickness?: number }>`
    display: inline-block;
    background-color: transparent;
    transform: rotate(-45deg) translateX(25%);
    border-style: solid;
    ${props => `
        width: ${props.width};
        height: ${props.width};
        border-width: ${props.strokeThickness || 2}px 0 0 ${props.strokeThickness || 2}px;
        border-color: black;
    `}
`;