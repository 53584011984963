import * as React from 'react';
import styled from 'styled-components';
import { DesktopFooterInfo } from './DesktopFooterInfo';
import { Copyright } from '../Copyright';
import { Certifications } from '../../Common/Certification/Certifications';

const StyledFooter = styled.div`
    position: relative;
    width: 100vw;
    height: max-content;
    background-color: ${props => props.theme.colors.mobyDickWhite};
`;

export const DesktopFooter: React.FC<{}> = () => {
    return (
        <StyledFooter>
            <DesktopFooterInfo />
            <Certifications />
            <Copyright />
        </StyledFooter>
    )
}