import * as React from 'react';
import styled from 'styled-components';
import { IHeaderMenuItem } from '../../../helpers/queries/headerFooter';
import Icons from '../../Icons';
import { MenuItem } from '../MenuItem';

interface MobileMenuAccordionProps {
    data: Required<IHeaderMenuItem>;
    resetOpenMenu: any;
}

const SubMenuButton = styled.button`
    width: 100%;
    height: max-content;
    margin-top: 1rem;
    padding-bottom: 1rem;
    color: ${props => props.theme.colors.nearlyNavy};
    font-family: ${props => props.theme.fonts.din};
    font-size: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.colors.casperGrey};
    &:last-child {
        border: none;
    }
`;

const ChevronRight = styled(Icons.ChevronRight)`
    float: right;
    border-color: ${props => props.theme.colors.nearlyNavy};
`;

const ChildMenuContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: inherit;
    background-color: ${props => props.theme.colors.almostWhite};
    z-index: 1;
`;

const ChildMenuHeading = styled.div`
    width: 100%;
    height: max-content;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    > h3 {
        flex: 0 0 auto;
        width: max-content;
        font-size: 16px;
        font-family: ${props => props.theme.fonts.dinNarrow};
        color: ${props => props.theme.colors.nearlyNavy};
        text-transform: uppercase;
    }

    > button {
        flex: 0 0 auto;
        width: max-content;
        font-size: 20px;
        font-family: ${props => props.theme.fonts.din};
        color: ${props => props.theme.colors.nearlyNavy};
    }
`;

const ChevronLeft = styled(Icons.ChevronLeft)`
    margin-right: .5rem;
    border-color: ${props => props.theme.colors.nearlyNavy};
`;

const ChildLinksWrapper = styled.div`
    margin: 1.5rem 0 2rem 1rem;
    width: 100%;
    height: max-content;
    border-left: 1px solid ${props => props.theme.colors.casperGrey};

    > a {
        margin: 0;
        padding: 1rem 0 1rem .5rem;
        border: none;
    }
`;

const PAGES_RELATING_TO_SOLUTIONS = ['Products', 'Product']

export const MobileMenuAccordion: React.FC<MobileMenuAccordionProps> = (props) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const withoutChildren = props.data.childItems.filter(child => !child.childItems?.length);
    const withChildren = props.data.childItems.filter(child => !!child.childItems?.length);

    const resetMenu = (reset) => {
        setIsOpen(reset);
        props.resetOpenMenu(reset)
    }

    return (
        <>
            {!isOpen &&
                <SubMenuButton onClick={() => setIsOpen(true)}>
                    {props.data.title}
                    <ChevronRight width="10px" />
                </SubMenuButton>
            }
            {isOpen &&
                <ChildMenuContainer>
                    {!!withoutChildren.length &&
                        <>
                            <ChildMenuHeading>
                                <h3>{props.data.title}</h3>
                                <button onClick={() => setIsOpen(false)}>
                                    <ChevronLeft width="10px" />
                                    Back
                                </button>
                            </ChildMenuHeading>
                            <ChildLinksWrapper>
                                {withoutChildren.map((menuItem, index) => {
                                    const key = `child-menu-item-${index + 1}`;
                                    return <MenuItem data={menuItem} key={key} />
                                })}
                            </ChildLinksWrapper>
                        </>
                    }
                    {withChildren.map((child, index) => (
                        <>
                            <ChildMenuHeading key={index}>
                                <h3>{child.title}</h3>
                                {index === 0 &&
                                    <button onClick={() => setIsOpen(false)}>
                                        <ChevronLeft width="10px" />
                                        Back
                                    </button>
                                }
                            </ChildMenuHeading>
                            <ChildLinksWrapper key={index}>
                                {child.childItems!.map((menuItem, linkIndex) => {
                                    let url = PAGES_RELATING_TO_SOLUTIONS.includes(child.title) ? `/solutions#${menuItem.url}` : menuItem.url
                                    url = url.replace('/product/', '')

                                    const key = `child-menu-item-${linkIndex + 1}`;
                                    return <MenuItem
                                        isOpen={isOpen}
                                        resetMenu={ resetMenu }
                                        data={ { url, title: menuItem.title, inSeperateBlock: menuItem.inSeperateBlock } }
                                        key={key}
                                    />
                                })}
                            </ChildLinksWrapper>
                        </>
                    ))}
                </ChildMenuContainer>
            }
        </>
    )
}