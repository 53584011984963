import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { HeaderProvider } from '@/src/contexts/HeaderContext';
import { getWindow } from '@/src/helpers/ssrSafeGlobals';
import { debounce } from '@/src/helpers/debounce';
import { AlertBar } from './AlertBar';
import { SiteLogo } from '../Common/SiteLogo';
import { DesktopMenu } from './DesktopMenu';
import { MobileMenu } from './MobileMenu';
import { Helmet } from 'react-helmet';

const StyledHeader = styled.header`
    position: relative;
    width: 100vw;
    height:86px;
    background-color: ${props => props.theme.colors.mobyDickWhite};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 25vw auto max-content;
    grid-template-areas: "logo . icons";
    z-index: 1000;
    align-items: center;
    @media(min-width: ${props => props.theme.screensizes.medium}) {
        grid-template-rows: 50% 50%;
        grid-template-columns: 15vw auto max-content max-content;
        grid-template-areas: "logo . site-info block-links"
                            "logo . menu-links block-links";
                       
    }
`;

const StyledLogo = styled(SiteLogo)`
    grid-area: logo;
    width: 100%;
    max-width: 184px;
    margin: 0 24px;
    padding: 1rem 0;
    min-width: 110px;

    @media(min-width: ${ props => props.theme.screensizes.xxlarge }) {
        margin: 0 40px;
    }
`;

export const Header: React.FC<{}> = () => {
    const [showMobileMenu, setShowMobileMenu] = React.useState(false);
    const theme = useTheme();

    const _handleResize = () => {
        setShowMobileMenu(window.innerWidth <= theme.screensizes.mediumValue);
    }
    const handleResize = debounce(_handleResize, 250);
    React.useEffect(() => {
        const w  = getWindow();

        handleResize();
        w.addEventListener('resize', handleResize);
        return () => { w.removeEventListener('resize', handleResize); }
    }, [])

    return (
        <>
            <Helmet>
                <script type="text/javascript" src="https://api.feefo.com/api/javascript/gas-monitor-point?colour=grey"></script>
            </Helmet>
            <div className="feefo-container">
                <div id="feefo-service-review-floating-widgetId"></div>
            </div>
           
            <AlertBar />
            <StyledHeader>
                <StyledLogo />
                <HeaderProvider>
                    {showMobileMenu ? <MobileMenu /> : <DesktopMenu />}
                </HeaderProvider>
            </StyledHeader>
        </>
    )
}