import * as React from 'react';
import styled, { css } from 'styled-components';
import { GlobalContext } from '@/src/contexts/GlobalContext';
import { Buttons } from '../../Common/Styles/Interactions';

const Actions = styled.div`
    width: 100%;

    > :first-child {
        margin-bottom: 24px;
    }

    > * {
        width: 100%;
        max-width: initial !important;
    }
`

export const ContactActions: React.FC<{}> = () => {
    const { phoneNumber, phoneNumber_displayReady } = React.useContext(GlobalContext).themeSettings.siteDetails;
    if (!phoneNumber || !phoneNumber_displayReady) {
        return null;
    }

    return (
        <Actions>
            <Buttons.Primary text="Contact Us" href="/contact-us/" />
            <Buttons.SecondaryAlt text={`Call us on: ${phoneNumber_displayReady}`} href={`tel: ${phoneNumber}`}/>
        </Actions>
    )
}
