import * as React from 'react';
import styled from 'styled-components';
import { PlayCircleOutline } from '@styled-icons/material-twotone';
import { navigate } from 'gatsby';
import { Buttons } from '../Styles/Interactions';

const SearchDropdown = styled.aside<{type: string}>`
    position: ${ props => props.type === 'default' ? 'absolute' : 'relative' };
    top: ${ props => props.type === 'default' ? '86px' : 'initial' };
    left: 0;
    width: ${ props => props.type === 'default' ? '100vw' : '100%' };
    height: max-content;
    background-color: ${ props => props.type === 'default' ? props.theme.colors.almostWhite : 'transparent' };
    z-index: ${ props => props.type === 'default' ? '-1' : 'initial' };
    margin-left: ${ props => props.type === 'default' ? 'calc(50% - 50vw)' : 'initial' };
    margin-right: ${ props => props.type === 'default' ? 'calc(50% - 50vw)' : 'initial' };
`;

const Form = styled.form<{type: string}>`
    width: 100%;
    height: max-content;
    padding: ${ props => props.type === 'default' ? '2rem 0' : '0' };
    background-color: transparent;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: stretch;
`;

const Input = styled.input<{type: string}>`
    width: 90%;
    height: max-content;
    padding: 10px;
    font-size: 16px;
    font-family: ${props => props.theme.fonts.din};
    color: ${props => props.theme.colors.greatlyGrey};
    font-weight: 300;

    border: ${ props => props.type === 'alternative' ? '1px solid #D2D3D9' : 'none' };
    min-height: ${ props => props.type === 'alternative' ? '50px' : 'initial' };

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        width: 50%;
    }

    ::placeholder {
        color: var(--greatlyGrey);
    }
`;

const SearchSubmit = styled.button`
    flex: 0 1 auto;
    width: max-content;
    background-color: ${props => props.theme.colors.mobyDickWhite};

    svg {
        position: relative;
        right: 10px;
    }
`;

interface ISearchInputProps {
    inputType?: string;
    onChange?: any;
    onSubmit?: any;
}

export const SearchInput: React.FC<ISearchInputProps> = (props) => {
    const [searchValue, setSearchValue] = React.useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setSearchValue(event.target.value);
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        navigate(`/search?${searchValue}`, { state: { searchQuery: searchValue }});
    }

    return (
        <SearchDropdown type={ props.inputType || 'default' }>
            <Form
                type={ props.inputType || 'default' }
                action="#"
                method="get"
                onSubmit={ props.onSubmit || handleSubmit }
            >
                <Input
                    type={ props.inputType || 'default' }
                    placeholder="Search for services, products and insights…"
                    onChange={ props.onChange || handleChange }
                    onSubmit={ props.onSubmit || handleSubmit }
                />
                <SearchSubmit type="submit">
                    {!props.inputType ? (
                        <PlayCircleOutline width={ '24' } height={ '24' }/>
                    ) : (
                        <Buttons.Primary text={ 'Search' }/>
                    )}
                </SearchSubmit>
            </Form>
        </SearchDropdown>
    )
}