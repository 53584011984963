const DummyFunction = (..._params: any[]) => {};

const SSR_WINDOW = {
    addEventListener: DummyFunction,
    removeEventListener: DummyFunction,
    scrollTo: DummyFunction,
    innerWidth: Infinity,
    location: {
        href: '',
    },
};

export function getWindow(): Window | typeof SSR_WINDOW {
    if (typeof window === 'undefined') return SSR_WINDOW;

    return window;
}

const SSR_DOCUMENT = {
    body: {
        classList: {
            add: DummyFunction,
            remove: DummyFunction,
            toggle: DummyFunction,
        }
    }
};

export function getDocument(): Document | typeof SSR_DOCUMENT {
    if (typeof document === 'undefined') return SSR_DOCUMENT;

    return document;
}