import * as React from 'react';
import styled from 'styled-components';
import { FooterContext } from '../../../contexts/FooterContext';
import { SiteLogo } from '../../Common/SiteLogo';
import { StoreDetails } from '../StoreDetails';
import { SiteLinks } from '../SiteLinks';
import { SocialLinks } from '../SocialLinks';
import { Collapsible } from '../../Common/Collapsible';

const StyledMobileFooterInfo = styled.div`
    position: relative;
    width: 100%;
    height: max-content;
    background-color: ${props => props.theme.colors.mobyDickWhite};
    .collapsible .title {
        font-family: ${props => props.theme.fonts.dinNarrow};
        font-size: 20px;
    }
`;

const StyledLogo = styled(SiteLogo)`
    width: 70%;
    margin: 0 auto 30px;
    text-align: center;
    display: block;

    @media (min-width: ${props => props.theme.screensizes.small}) {
        width: 50%;
    }
`;

export const MobileFooterInfo: React.FC<{}> = () => {
    const footerContext = React.useContext(FooterContext);
    const mainLists = footerContext.lists;
    const finalList = mainLists.pop()!;

    return (
        <StyledMobileFooterInfo className="footer-info footer-info--desktop">
            <StyledLogo />
            <Collapsible title="Contact Us">
                <StoreDetails />
            </Collapsible>
            {mainLists.map((list, index) => (
                <Collapsible title={list.title} key={index}>
                    <SiteLinks links={list.children} />
                </Collapsible>
            ))}
            <SocialLinks header={finalList.title} links={finalList.children} />
        </StyledMobileFooterInfo>
    )
}
