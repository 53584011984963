import * as React from 'react';
import styled from 'styled-components';
import { HeaderContext } from '@/src/contexts/HeaderContext';
import { MenuItem } from '../MenuItem';
import { DesktopMenuAccordion } from './DesktopMenuAccordion';
import { SearchBar } from '../SearchBar';
import { ObfuscatedLink } from '../../Common/ObfuscatedLink';
import { IHeaderMenuItem } from '../../../helpers/queries/headerFooter';

// Context
import { GlobalContext } from '../../../contexts/GlobalContext';

const SiteInfoWrapper = styled.div`
    grid-area: site-info;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > a {
        flex: 0 0 auto;
        display: inline-block;
        margin-left: 1rem;
        font-size: 16px;
        font-family: ${props => props.theme.fonts.din};
        color: ${props => props.theme.colors.greatlyGrey};
    }
`;

const MenuLinksWrapper = styled.div`
    grid-area: menu-links;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`;

const BlockLinksWrapper = styled.div`
    grid-area: block-links;
    height: 100%;
`;

const BlockLink = styled.a`
    position: relative;
    display: inline-block;
    width: max-content;
    height: 100%;
    padding: 0 2rem;
    background-color: ${props => props.theme.colors.meaningfulMarigold};
    font-size: 18px;
    font-weight: 600;
    font-family: ${props => props.theme.fonts.din};
    color: ${props => props.theme.colors.nearlyNavy};
    text-decoration: none;

    > span {
        display: block;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    &:first-of-type {
        margin-left: 2rem;
    }
`;

export const DesktopMenu: React.FC<{}> = (props) => {
    const headerContext = React.useContext(HeaderContext);
    const regularLinkItems = new Array<IHeaderMenuItem>();
    const blockLinkItems = new Array<IHeaderMenuItem>();
    headerContext.menuItems.forEach(menuItem => {
        (!menuItem.inSeperateBlock || menuItem.childItems?.length) && regularLinkItems.push(menuItem);
        menuItem.inSeperateBlock && blockLinkItems.push(menuItem);
    });
    const { siteDetails } = React.useContext(GlobalContext).themeSettings
    const regularLinksContainerRef = React.createRef<HTMLDivElement>();
    const [openMenu, setOpenMenu] = React.useState('');

    return (
            <>
            <SiteInfoWrapper>
                <ObfuscatedLink tel={ siteDetails.phoneNumber } linkText={`Call us on: ${ siteDetails.phoneNumber_displayReady }`} />
                <a href={ siteDetails.storeLink } target="_blank">Shop Online</a>
            </SiteInfoWrapper>
            <MenuLinksWrapper ref={regularLinksContainerRef}>
                {regularLinkItems.map((menuItem, index) => {
                    const key = `menu-item-${index + 1}`
                    return menuItem.childItems?.length
                        ? <DesktopMenuAccordion
                            data={menuItem as Required<IHeaderMenuItem>}
                            containerRef={regularLinksContainerRef}
                            openMenu={openMenu}
                            setOpenMenu={setOpenMenu}
                            key={key}
                            />
                        : <MenuItem data={menuItem} key={key} />
                })}
                <SearchBar
                    containerRef={regularLinksContainerRef}
                    openMenu={openMenu}
                    setOpenMenu={setOpenMenu}
                />
            </MenuLinksWrapper>
            <BlockLinksWrapper>
                {blockLinkItems.map((menuItem, index) => {
                    const key = `block-menu-item-${index + 1}`;
                    return (
                        <BlockLink href={menuItem.url} key={key}>
                            <span>{menuItem.title}</span>
                        </BlockLink>
                    );
                })}
            </BlockLinksWrapper>
        </>
    );
}