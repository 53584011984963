import * as React from 'react';
import { PageProps } from 'gatsby';
import { theme } from './theme';
import { ThemeProvider } from 'styled-components';
import { GlobalProvider } from '../contexts/GlobalContext';
import GlobalStyles from './GlobalStyles';
import { ApolloProvider } from '@apollo/client';
import { client } from './apollo/apollo-client';
import {
    Header,
    Footer,
    Sitewide
} from '../Components';
import SEO from '../Components/Sitewide/seo';

export const DefaultLayout: React.FC<PageProps> = (props) => {
    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <SEO
                    location={props?.path}
                />
                <GlobalProvider location={ props.location }>
                <GlobalStyles />
                    <Header />
                    <main>{props.children}</main>
                    { !props.location.pathname.includes('contact-us') && (
                        <Sitewide.ContactUs />
                    )}
                    <Sitewide.NewsletterSubscribe />
                    <Footer />
                </GlobalProvider>
            </ThemeProvider>
        </ApolloProvider>
    )
}