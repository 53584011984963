import * as React from 'react';
import { IHeaderMenuItem } from '@/src/helpers/queries/headerFooter';
import styled from 'styled-components';
import PostGrid from '../../Common/Posts/PostGrid';

interface DropdownMenuProps {
    topMargin: number;
    data: IHeaderMenuItem;
}

const MenuContainer = styled.aside<{topMargin: number}>`
    position: absolute;
    top: 86px;
    left: 0;
    width: 100vw;
    height: max-content;
    min-height: 20vh;
    z-index: -1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    background-color: ${props => props.theme.colors.mobyDickWhite};
`;

const MenuSection = styled.section<{widthPercentage: number, isFirst: boolean}>`
    flex: 1 0 auto;
    width: ${props => props.widthPercentage}vw;
    max-width: 50vw;
    min-width: 33vw;
    height: auto;
    padding: 3% 5%;
    background-color: ${props => props.isFirst ? props.theme.colors.almostWhite : 'transparent'};
`;

const FeaturedPostContainer = styled.div`
    flex: 1 0 auto;
    width: 50%;
    max-width: 50vw;
    min-width: 33vw;
    height: auto;
    padding: 3% 2% 0%;
    background-color: ${props => props.theme.colors.mobyDickWhite};
    .content-container {
        padding: 0px !important;
        max-width: 286px;
         a {
            flex: 0 0 100%;
            margin-bottom: 30px;
            h3 {
                margin: 15px 0px;
                font-size: 16px;
            }
        }
        .img-wrapper {
            height: 187px;
        }
    }
`

const Title = styled.h2`
    margin-bottom: 2rem;
    text-align: left;
    font-size: 24px; 
    font-family: ${props => props.theme.fonts.dinNarrow};
    font-weight: 800;
    text-transform: uppercase;
    color: ${props => props.theme.colors.nearlyNavy};

    > a {
        color: inherit;
        text-decoration: none;
    }
`;

const MenuLinkListContainer = styled.div<{isFirst: boolean}>`
    float: ${props => props.isFirst ? 'right' : 'none'};
    width: max-content;
    height: max-content;
`;

const MenuLinkList = styled.ol<{isFirst: boolean}>`
`;

const MenuLink = styled.li`
    margin-bottom: 1rem;
    font-size: 16px;
    font-family: ${props => props.theme.fonts.din};
    color: ${props => props.theme.colors.nearlyNavy};

    > a {
        color: inherit;
        text-decoration: none;
    }
`;

const PAGES_RELATING_TO_SOLUTIONS = ['Products', 'Product']

export const DesktopDropdown: React.FC<DropdownMenuProps> = ({ topMargin, data }) => {
    if (!data.childItems) return null;
    const singleItems = new Array<IHeaderMenuItem>();
    const itemsWithChildren = new Array<IHeaderMenuItem>();
    data.childItems.forEach(menuItem => {
        !menuItem.childItems?.length && singleItems.push(menuItem);
        menuItem.childItems?.length && itemsWithChildren.push(menuItem);
    });
    const numberOfMenuSections = singleItems.length + itemsWithChildren.length;
    const sectionWidths = (1 / numberOfMenuSections) * 100;

    return (
        <MenuContainer topMargin={topMargin}>
            {!!singleItems.length &&
               <>
                    <MenuSection widthPercentage={sectionWidths} isFirst={true} key="menu-section-1">
                        <MenuLinkListContainer isFirst={true}>
                            <Title>
                                <a href={data.url}>{data.title}</a>
                            </Title>
                            <MenuLinkList isFirst={true}>
                                {singleItems.map((menuItem, index) => {
                                    const key = `menu-section-1--link-${index + 1}`
                                    
                                    return (
                                        <MenuLink key={key}>
                                            <a href={menuItem.url}>{menuItem.title}</a>
                                        </MenuLink>
                                    );
                                })}
                            </MenuLinkList>
                        </MenuLinkListContainer>
                    </MenuSection>
                    <FeaturedPostContainer>
                        <Title>
                            <a href="">Featured</a>
                        </Title>
                            <PostGrid postsCount={1} hasPagination={false} hideDate={true}/>
                    </FeaturedPostContainer>
                </>
            }
            {itemsWithChildren.map((menuItem, index) => {
                const sectionKey = `menu-section-${index + singleItems.length ? 2 : 1}`

                return (
                    <MenuSection widthPercentage={sectionWidths} isFirst={!singleItems.length && index === 0} key={sectionKey}>
                        <MenuLinkListContainer isFirst={!singleItems.length && index === 0}>
                            <Title>
                                <a href={menuItem.url}>{menuItem.title}</a>
                            </Title>
                            <MenuLinkList isFirst={!singleItems.length && index === 0}>
                                {menuItem.childItems!.map((childItem, childIndex) => {
                                    const linkKey = `${sectionKey}-${childIndex + 1}`;

                                    let url = PAGES_RELATING_TO_SOLUTIONS.includes(menuItem.title) ? `/solutions#${childItem.url}` : childItem.url
                                    url = url.replace('/product/', '')

                                    return (
                                        <MenuLink key={linkKey}>
                                            <a href={ url }>{childItem.title}</a>
                                        </MenuLink>
                                    );
                                })}
                            </MenuLinkList>
                        </MenuLinkListContainer>
                    </MenuSection>
                );
            })
            }
        </MenuContainer>
    );
}